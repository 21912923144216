import React, { Component } from 'react';
import Helmet from 'react-helmet';
import './index.module.scss';
import favicon from '../assets/images/fav.png';

/* eslint-disable-next-line */
export interface IndexProps {}
export class Index extends Component {
  render() {
    const { children } = this.props;

    return (
      <main className="page-fixed wrapper" id="scroll">
        <Helmet
          link={[
            {
              rel: 'shortcut icon',
              type: 'image/png',
              href: `${favicon as string}`,
            },
          ]}
        />
        {children}
      </main>
    );
  }
}

export default Index;
