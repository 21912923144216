import './header.module.scss';
import logo from '../../assets/images/logo.png';
import Menu from '../menu/menu';
/* eslint-disable-next-line */
export interface HeaderProps {}

export function Header(props: HeaderProps) {
  return (
    <header>
      <div className="container-xl container">
        <div className="header-row">
          <div className="logo">
            <a href={'/'}>
              <img src={logo} alt="DGM" />
            </a>
          </div>
          <div className="navigation">
            <Menu />
            <div className="nav-btn">
              <a href={'/contact-us/request-form'} className="btn-theme btn-sm">
                Start Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
