import { useState } from 'react';
import './menu.module.scss';

/* eslint-disable-next-line */
export interface MenuProps {}

export function Menu(props: MenuProps) {
  const [isActive, setActive] = useState('false');
  const ToggleClass = () => {
    setActive(!isActive);
  };
  return (
    <div className="navigation-menu">
      <button onClick={ToggleClass} className="toggle-menu">
        <span></span>
        <span></span>
        <span></span>
      </button>
      <ul className={isActive ? null : 'active'}>
        <li>
          <a href="/">Home</a>
        </li>
        <li>
          <a>Our Services</a>
          <div className="dropdown-menu">
            <div className="col">
              <a
                href="/our-services/services-for-Individuals"
                className="small-heading text-nowrap color-white"
              >
                Services for Individuals
              </a>
              <ul>
                <li>
                  <a href="/our-services/services-for-Individuals/wealth-planning-services">
                    Wealth Planning
                  </a>
                </li>
                <li>
                  <a href="/our-services/services-for-Individuals/portfolio-management">
                    Portfolio Management
                  </a>
                </li>
                <li>
                  <a href="/our-services/services-for-Individuals/estate-planning">
                    Estate Planning
                  </a>
                </li>
                <li>
                  <a href="/our-services/services-for-Individuals/insurance-and-tax-strategies">
                    Insurance and Tax Strategies
                  </a>
                </li>
                <li>
                  <a href="/our-services/services-for-Individuals/socially-responsible-investing">
                    Socially Responsible Investing
                  </a>{' '}
                </li>
              </ul>
            </div>
            <div className="col">
              <a
                href="/our-services/services-for-businesses"
                className="small-heading text-nowrap color-white"
              >
                Services For Businesses
              </a>
              <ul>
                <li>
                  <a href="/our-services/services-for-businesses/retirement-plan-services">
                    Retirement Plan Services
                  </a>
                </li>
                <li>
                  <a href="/our-services/services-for-businesses/business-succession-planning">
                    Business Succession Planning
                  </a>
                </li>
                <li>
                  <a href="/our-services/services-for-businesses/institutional-cash-management">
                    Institutional Cash Management
                  </a>
                </li>
                <li>
                  <a href="/our-services/services-for-businesses/bespoke-business-services">
                    Bespoke Business Services
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li>
          <a href="/our-specialities">Our Specialities</a>
        </li>
        <li>
          <a href="/who-we-are">Who We Are</a>
        </li>
        <li>
          <a href="/contact-us">Contact Us</a>
        </li>
        <li>
          <a href="/resources">Resources</a>
        </li>
      </ul>
    </div>
  );
}

export default Menu;
